<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-form ref="form" @submit.prevent="validate">
          <v-card-title>Tambah</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Title"
                  placeholder="Title"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.title"
                  required
                  :rules="[v => !!v || 'Title harus diisi!']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="URL"
                  placeholder="URL"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.url"
                  required
                  :rules="[v => !!v || 'URL harus diisi!']"
                ></v-text-field>
                <a href="javascript:void(0)" @click="seoURL">Generate URL</a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Purchase Link"
                  placeholder="Purchase Link"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.purchase_link"
                  required
                  :rules="[v => !!v || 'Purchase Link harus diisi!']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Deskripsi"
                  placeholder="Deskripsi"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="data.description"
                  required
                  :rules="[v => !!v || 'Deskripsi harus diisi!']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-5">
                <h3>Sections</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Gambar</th>
                        <th>Header</th>
                        <th>Footer</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(section, index) in data.sections" :key="index">
                        <td>
                          <v-btn
                            color="primary"
                            outlined
                            depressed
                            small
                            :id="`button-upload-image-${section.id}`"
                            @click="openFile(`attach_image_${section.id}`)"
                          >
                            {{ section.filename }}
                          </v-btn>
                          <input
                            type="file"
                            :id="`attach_image_${section.id}`"
                            style="display: none"
                            @change="
                              changeFile(
                                $event,
                                `image_${section.id}`,
                                `button-upload-image-${section.id}`,
                                0,
                                `file_name_image_${section.id}`,
                              )
                            "
                          />
                          <input type="hidden" :id="`image_${section.id}`" />
                          <input type="hidden" :id="`file_name_image_${section.id}`" />
                        </td>
                        <td class="py-5">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Title"
                                placeholder="Title"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.header.title"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Subtitle"
                                placeholder="Subtitle"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.header.subtitle"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Background Color"
                                placeholder="Background Color"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.header.background_color"
                              >
                                <template v-slot:append>
                                  <v-menu
                                    v-model="section.header.menu_background_color"
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        :style="{
                                          backgroundColor: section.header.background_color,
                                          cursor: 'pointer',
                                          height: '30px',
                                          width: '30px',
                                          borderRadius: section.header.menu_background_color ? '50%' : '4px',
                                          transition: 'border-radius 200ms ease-in-out',
                                        }"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-card>
                                      <v-card-text class="pa-0">
                                        <v-color-picker v-model="section.header.background_color" flat />
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Text Color"
                                placeholder="Text Color"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.header.text_color"
                              >
                                <template v-slot:append>
                                  <v-menu
                                    v-model="section.header.menu_text_color"
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        :style="{
                                          backgroundColor: section.header.text_color,
                                          cursor: 'pointer',
                                          height: '30px',
                                          width: '30px',
                                          borderRadius: section.header.menu_text_color ? '50%' : '4px',
                                          transition: 'border-radius 200ms ease-in-out',
                                        }"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-card>
                                      <v-card-text class="pa-0">
                                        <v-color-picker v-model="section.header.text_color" flat />
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </td>
                        <td class="py-5">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Title"
                                placeholder="Title"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.footer.title"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Subtitle"
                                placeholder="Subtitle"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.footer.subtitle"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Background Color"
                                placeholder="Background Color"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.footer.background_color"
                              >
                                <template v-slot:append>
                                  <v-menu
                                    v-model="section.footer.menu_background_color"
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        :style="{
                                          backgroundColor: section.footer.background_color,
                                          cursor: 'pointer',
                                          height: '30px',
                                          width: '30px',
                                          borderRadius: section.footer.menu_background_color ? '50%' : '4px',
                                          transition: 'border-radius 200ms ease-in-out',
                                        }"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-card>
                                      <v-card-text class="pa-0">
                                        <v-color-picker v-model="section.footer.background_color" flat />
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Text Color"
                                placeholder="Text Color"
                                outlined
                                dense
                                hide-details="auto"
                                v-model="section.footer.text_color"
                              >
                                <template v-slot:append>
                                  <v-menu
                                    v-model="section.footer.menu_text_color"
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <div
                                        :style="{
                                          backgroundColor: section.footer.text_color,
                                          cursor: 'pointer',
                                          height: '30px',
                                          width: '30px',
                                          borderRadius: section.footer.menu_text_color ? '50%' : '4px',
                                          transition: 'border-radius 200ms ease-in-out',
                                        }"
                                        v-on="on"
                                      />
                                    </template>
                                    <v-card>
                                      <v-card-text class="pa-0">
                                        <v-color-picker v-model="section.footer.text_color" flat />
                                      </v-card-text>
                                    </v-card>
                                  </v-menu>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </td>
                        <td>
                          <v-btn color="error" depressed small @click="deleteSection(section)">Hapus</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <v-btn color="info" small @click="addSection">Tambah</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-5">
                <h3>Marketplaces</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Purchase Link</th>
                        <th>Link</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(marketplace, index) in data.marketplaces" :key="index">
                        <td class="py-5">
                          <v-select
                            label="Purchase Link"
                            outlined
                            dense
                            v-model="marketplace.purchase_link_id"
                            :items="purchase_links"
                            item-text="name"
                            item-value="id"
                          ></v-select>
                        </td>
                        <td class="py-5">
                          <v-text-field label="Link" outlined dense v-model="marketplace.link"></v-text-field>
                        </td>
                        <td>
                          <v-btn color="error" depressed small @click="deleteMarketplace(marketplace)">Hapus</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <v-btn color="info" small @click="addMarketplace">Tambah</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit" :loading="loadingButton">Simpan</v-btn>
            <v-btn color="primary" outlined class="ml-3" @click="$router.go(-1)">Batal</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline, mdiClose } from '@mdi/js'
import axios from 'axios'
import { shortlessDateTime, seoURL } from '@/utils'

export default {
  setup() {
    return {
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
        mdiClose,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
    id() {
      return this.$route.params.id
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingButton: false,
      data: {
        title: '',
        description: '',
        url: '',
        purchase_link: '',
        sections: [],
        marketplaces: [],
      },
      purchase_links: [],
    }
  },
  mounted() {
    this.getPurchaseLinks()
    this.getData()
  },
  methods: {
    getPurchaseLinks() {
      const token = localStorage.getItem('token')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}purchase-link?page=1&limit=999`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.purchase_links = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    getData() {
      const token = localStorage.getItem('token')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}product-page/${this.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          const data = res.data.data
          this.data = data
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    changeFileVariable() {
      this.data.sections.map(section => {
        const section_element = document.getElementById(`image_${section.id}`)
        const filename_section = document.getElementById(`file_name_image_${section.id}`)
        section.file = section_element.value
        if (section.file != '') {
          section.filename = filename_section.value
        }
        return section
      })
    },
    seoURL() {
      this.data.url = seoURL(this.data.title)
    },
    addSection() {
      const length = this.data.sections.length
      const section = {
        id: length,
        file: '',
        filename: '',
        old_image_url: '',
        header: {
          title: '',
          subtitle: '',
          background_color: '',
          text_color: '',
          menu_background_color: false,
          menu_text_color: false,
        },
        footer: {
          title: '',
          subtitle: '',
          background_color: '',
          text_color: '',
          menu_background_color: false,
          menu_text_color: false,
        },
      }
      this.data.sections.push(section)
    },
    deleteSection(section) {
      const filtered = this.data.sections.filter(item => {
        return item.id !== section.id
      })
      this.data.sections = filtered
    },
    addMarketplace() {
      const length = this.data.marketplaces.length
      const marketplace = {
        id: length,
        purchase_link_id: '',
        link: '',
      }
      this.data.marketplaces.push(marketplace)
    },
    deleteMarketplace(marketplace) {
      const filtered = this.data.marketplaces.filter(item => item.id !== marketplace.id)
      this.data.marketplaces = filtered
    },
    openFile(id) {
      document.getElementById(id).click()
    },
    changeFile(event, id, buttonID, maxSize = 0, fileNameElementID) {
      const selectedElement = document.getElementById(id)
      const fileNameElement = document.getElementById(fileNameElementID)
      const button = document.getElementById(buttonID)
      const file = event.target.files[0]
      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)

        if (maxSize > 0 && size > maxSize) {
          this.alert = {
            success: false,
            status: true,
            message: 'File terlalu besar!',
          }
          return
        }

        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          selectedElement.value = base64String
        }
        reader.readAsDataURL(file)
        button.innerHTML = file.name.length > 20 ? file.name.slice(0, 18) + '...' : file.name
        fileNameElement.value = file.name
      }
    },
    validate() {
      this.changeFileVariable()
      this.save()
    },
    save() {
      const token = localStorage.getItem('token')
      console.log(this.data)
      axios
        .patch(`${process.env.VUE_APP_API_ENDPOINT}product-page/${this.id}`, this.data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.alert = {
            status: true,
            success: res.status == 200,
            message: res.data.message,
          }
          this.$router.go(-1)
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style>
</style>